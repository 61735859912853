import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import treatmentTypes from "./treatmentTypes.json"; // Importando os dados do JSON

export default function TiposTratamento() {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleCardMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleCardMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <Container sx={{ textAlign: "center", maxWidth: "80vw", margin: "0 auto" }}>
      <Typography variant="h4" component="h1" sx={{ marginBottom: "2rem" }}>
        {`Tipos de Tratamento`.toUpperCase()}
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {treatmentTypes.map((treatment, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                cursor: "pointer",
                position: "relative",
                overflow: "visible",
                borderRadius: 3,
              }}
              onMouseEnter={() => handleCardMouseEnter(index)}
              onMouseLeave={handleCardMouseLeave}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  component="text"
                  sx={{ marginBottom: "1rem" }}
                >
                  {treatment.title}
                </Typography>
                {hoveredIndex === index && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: "#fff",
                      zIndex: 1,
                      padding: "1rem",
                      boxShadow: "8px 8px 8px rgba(93, 56, 55, 0.75)",
                      borderRadius: 3,
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="text"
                      gutterBottom
                      
                    >
                      {treatment.title}
                    </Typography>
                    <Typography variant="body1" gutterBottom mt={2}>
                      {treatment.description}
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
