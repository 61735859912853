import React from "react";
import { Container, Typography, Box } from "@mui/material";

export default function Psicoterapia() {
  return (
    <Container sx={{ textAlign: "center", maxWidth: "80vw", margin: "0 auto" }}>
      <Box sx={{ marginBottom: "1rem" }}>
        <Typography variant="h2" component="h1" sx={{ marginBottom: "1rem" }}>
          O que é psicoterapia?
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: "1.5rem" }}>
          Você já ouviu falar em{" "}
          <span>
            <strong>psicoterapia?</strong>
          </span>{" "}
          Esse é um processo que proporciona ao paciente adquirir consciência
          sobre seus próprios atos, é algo que precisa ser feito em médio a
          longo prazo, dessa forma, ele terá tempo para explorar totalmente a
          sua insegurança, dor, emoção ou experiência e compreender a situação.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "1.5rem" }}>
          Acredito que a fala sobre si dá ao paciente a oportunidade de sentir e
          entrar em contato consigo mesmo. Assim, consegue perceber os conteúdos
          significativos da sua vida, dar novos sentidos e construir novas
          experiências com abordagens criativas.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "1.5rem" }}>
          Assim, o paciente tem a oportunidade de{" "}
          <span>
            <strong>resolver conflitos</strong>
          </span>{" "}
          internos, desenvolver um melhor relacionamento consigo mesmo e com
          quem está ao seu redor, ao mesmo tempo que desenvolve o autoamor, a
          responsabilidade por seus atos, amadurecimento e autoconhecimento.
        </Typography>
      </Box>
    </Container>
  );
}
