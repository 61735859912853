import { Box, Typography, Link } from "@mui/material";
import { FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import { useTheme } from "@mui/system";

function Footer() {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        py: 4,
        px: { xs: 2, sm: 4 },
        mt: "auto",
      }}
      width="100%"
    >
      <Box display="flex" justifyContent="center" mb={2}>
        <Link
          href="https://www.instagram.com/psipabla/"
          target="_blank"
          rel="noreferrer"
          aria-label="Instagram"
          sx={{
            color: theme.palette.primary.contrastText,
            mx: 1,
            fontSize: 28,
          }}
        >
          <FaInstagram />
        </Link>
        <Link
          href="https://www.linkedin.com/in/psi-pabla/"
          target="_blank"
          rel="noreferrer"
          aria-label="Linkedin"
          sx={{
            color: theme.palette.primary.contrastText,
            mx: 1,
            fontSize: 28,
          }}
        >
          <FaLinkedin />
        </Link>
        <Link
          href="https://api.whatsapp.com/send?phone=555196950402&text=Olá%20Pabla,%20gostaria%20de%20mais%20informações%20sobre%20a%20psicoterapia."
          target="_blank"
          rel="noreferrer"
          aria-label="Whatsapp"
          sx={{ color: theme.palette.primary.contrastText, mx: 1, fontSize: 28 }}
        >
          <FaWhatsapp />
        </Link>
      </Box>
      <Typography variant="body2" align="center" mb={1}>
        © 2023 Direitos Reservados | <span>Psicóloga Pabla Amaral</span>
      </Typography>
      <Typography variant="body2" align="center">
        Developed by{" "}
        <Link
          href="https://www.edtech1985.com.br/"
          target="_blank"
          rel="noreferrer"
          sx={{ color: theme.palette.primary.contrastText }}
        >
          <span>edtech1985</span>
        </Link>
      </Typography>
    </Box>
  );
}

export default Footer;
