import { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Box,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home"; 
import logo from "../../img/logo.png";
import { useTheme } from "@mui/system";

function Header() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { label: "Psicoterapia", path: "/psicoterapia" },
    { label: "Tratamentos", path: "/tratamentos" },
    { label: "Depoimentos", path: "/depoimentos" },
    { label: "Sobre Mim", path: "/sobre" },
    { label: "Contato", path: "/contato" },
  ];

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: theme.palette.secondary.main }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box display="flex" alignItems="center">
          <NavLink to="/" style={{ textDecoration: "none", color: "inherit" }}>
            <Box
              component="img"
              src={logo}
              alt="Símbolo da Psicologia"
              sx={{ height: 40, mr: 4 }}
            />
          </NavLink>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: theme.palette.primary.contrastText }}
          >
            Psicóloga Pabla Amaral
          </Typography>
        </Box>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{ display: { xs: "none", md: "flex" }, marginLeft: "auto" }}
            >
              <IconButton
                component={NavLink}
                to="/"
                edge="start"
                color="inherit"
                aria-label="home"
              >
                <HomeIcon />
              </IconButton>
              {menuItems.map((item) => (
                <Button
                  key={item.label}
                  color="inherit"
                  component={NavLink}
                  to={item.path}
                  onClick={handleClose}
                  sx={{
                    ml: 2,
                    color: theme.palette.primary.contrastText,
                    fontSize: "0.7rem",
                  }}
                >
                  {item.label}
                </Button>
              ))}
            </Box>
            <Box
              sx={{ display: { xs: "flex", md: "none" }, marginLeft: "auto" }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuClick}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {menuItems.map((item) => (
                  <MenuItem
                    key={item.label}
                    component={NavLink}
                    to={item.path}
                    onClick={handleClose}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
