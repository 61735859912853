import { Box, Fab, IconButton, Link } from "@mui/material";
import { FaWhatsapp } from "react-icons/fa";

const WhatsAppFloatingButton = () => {
  return (
    <Box
      position="fixed"
      right={30}
      bottom={30}
      zIndex={1000}
    >
      <Fab sx={{ bgcolor: "green" }} aria-label="WhatsApp">
        <IconButton
          component={Link} 
          href="https://api.whatsapp.com/send?phone=555196950402&text=Olá%20Pabla,%20gostaria%20de%20mais%20informações%20sobre%20a%20psicoterapia."
          target="_blank"
          rel="noreferrer"
          aria-label="Contatar Psicologa Pabla Amaral pelo WhatsApp"
          sx={{ color: "white" }}
        >
          <FaWhatsapp />
        </IconButton>
      </Fab>
    </Box>
  );
};

export default WhatsAppFloatingButton;
