import { Container, Typography, Card, CardContent, Grid } from "@mui/material";

export default function Depoimentos() {
  return (
    <Container sx={{ textAlign: "center", maxWidth: "80vw", margin: "0 auto" }}>
      <Typography component="h1" variant="h4" sx={{ marginBottom: "2rem" }}>
        {`Depoimentos`.toUpperCase()}
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxShadow: "8px 8px 8px rgba(93, 56, 55, 0.75)",
              borderRadius: 3,
              transition: "transform 0.3s ease-in-out", // Adiciona transição suave
              "&:hover": {
                transform: "scale(1.1)", // Aumenta a escala em 10% no hover
              },
            }}
          >
            <CardContent>
              <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
                "Estou aprendendo a não me abalar com o que estão pensando a meu
                respeito, principalmente, com aquelas pessoas que provavelmente
                não vou ver nunca mais. Será que a opinião da família, dos
                amigos e dos colegas de trabalho importam? Sim, porém importa
                mais eu me respeitar e não colocar em segundo plano o que gosto
                e quero só para agradá-los. Hoje estou mais segura que posso
                controlar melhor minha ansiedade.”
              </Typography>
              <Typography variant="body1" gutterBottom fontWeight="bold">
                G. A.
              </Typography>
              <Typography variant="body2" className="stars">
                ⭐️⭐️⭐️⭐️⭐️
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxShadow: "8px 8px 8px rgba(93, 56, 55, 0.75)",
              borderRadius: 3,
              transition: "transform 0.3s ease-in-out", // Adiciona transição suave
              "&:hover": {
                transform: "scale(1.1)", // Aumenta a escala em 10% no hover
              },
            }}
          >
            <CardContent>
              <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
                “Você foi uma pessoa fundamental nesta caminhada. Muito obrigada
                por tudo que você fez por nós e por mim, em especial. Você
                realmente é a psicóloga que faz a diferença na vida das pessoas.
                Um grande abraço!”
              </Typography>
              <Typography variant="body1" gutterBottom fontWeight="bold">
                P. G.
              </Typography>
              <Typography variant="body2" className="stars">
                ⭐️⭐️⭐️⭐️⭐️
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxShadow: "8px 8px 8px rgba(93, 56, 55, 0.75)",
              borderRadius: 3,
              transition: "transform 0.3s ease-in-out", // Adiciona transição suave
              "&:hover": {
                transform: "scale(1.1)", // Aumenta a escala em 10% no hover
              },
            }}
          >
            <CardContent>
              <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
                “Pabla você foi tão legal comigo, entendeu rapidamente o meu
                problema e, como um “passe de mágica”, me mostrou o que fazer
                para poder acabar de vez com o que me atormentava. Sou muito
                feliz mesmo por estar fazendo essa terapia com você! Obrigada!”
              </Typography>
              <Typography variant="body1" gutterBottom fontWeight="bold">
                A.T.
              </Typography>

              <Typography variant="body2" className="stars">
                ⭐️⭐️⭐️⭐️⭐️
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
