import React from "react";
import { Container, Typography, Box } from "@mui/material";

export default function SobreMim() {
  return (
    <Container sx={{ textAlign: "center", maxWidth: "80vw", margin: "0 auto" }}>
      <Box sx={{ marginBottom: "1rem" }}>
        <Typography variant="h3" component="h1" sx={{ marginBottom: "0.5rem" }}>
          Pabla Amaral
        </Typography>
        <Typography variant="subtitle1" component="h2" sx={{ marginBottom: "1rem" }}>
          CRP 07/37847
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: "1.5rem" }}>
          Meu nome é Pabla Amaral, sou <span>psicóloga</span> com formação pela
          Fadergs. Atualmente trabalho com a
          <span>
            {" "}
            <b>Terapia Cognitivo-Comportamental</b>
          </span>
          , e possuo capacitação adicional em Mindfulness e Terapia de Casal.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "1.5rem" }}>
          Meu foco principal é na
          <span>
            <b> promoção da saúde mental</b>
          </span>
          , oferecendo um suporte completo e dedicado aos meus pacientes. Tenho
          experiência na correção e elaboração de pareceres e testes de
          personalidade, e testes de inteligência não verbal. Na minha prática
          clínica, atendo indivíduos com uma variedade de desafios, incluindo
          ansiedade, depressão, estresse, questões relacionadas a
          relacionamentos, gestação, luto, traumas e conflitos familiares, bem
          como inseguranças e outras demandas psicológicas.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "1.5rem" }}>
          Busco desenvolver habilidades emocionais e sociais nos meus pacientes,
          promovendo uma melhor
          <span>
            <b> qualidade de vida</b>
          </span>{" "}
          e{" "}
          <span>
            <b>inteligência emocional</b>
          </span>{" "}
          através do autoconhecimento.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "1.5rem" }}>
          Convido você a vivenciar esse{" "}
          <span>
            <b>processo terapêutico enriquecedor</b>
          </span>
          , que tem como objetivo ampliar a sua percepção, proporcionando uma
          maior compreensão de si mesmo, do mundo e das suas relações.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: "1.5rem" }}>
          <span>
            <b>Agende uma consulta</b>
          </span>{" "}
          e inicie o caminho rumo ao bem-estar e à realização.
        </Typography>

        <Typography variant="body1">Estou pronta para ajudá-lo(a)</Typography>
      </Box>
    </Container>
  );
}
