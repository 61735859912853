import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5d3837", // --text
    },
    secondary: {
      main: "#a0746d", // --dark-brown
    },
    background: {
      default: "#FDECDB", // --nude
    },
  },
  typography: {
    fontFamily: "Libre Baskerville, serif",
    h1: {
      fontSize: "2.4rem",
      color: "#5d3837",
    },
    h2: {
      fontSize: "2.0rem",
      color: "#5d3837",
    },
    h3: {
      fontSize: "1.8rem",
      color: "#5d3837",
    },
    h4: {
      fontSize: "1.6rem",
      color: "#5d3837",
    },
    h5: {
      fontSize: "1.4rem",
      color: "#5d3837",
    },
    h6: {
      fontFamily: "Dancing Script, cursive",
      fontSize: "1.2rem",
      color: "#5d3837",
    },
    body1: {
      fontSize: "1.0rem",
      color: "#4f3837",
    },
  },
});

export default theme;
