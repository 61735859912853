const GoogleTag = () => (
    <>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-MHW99HF1MP"></script>
        <script>
            {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-MHW99HF1MP');
      `}
        </script>
    </>
);

export default GoogleTag;
