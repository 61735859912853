import { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
} from "@mui/material";

// URLs dos produtos no Hotmart
const hotmartProducts = [
  {
    title: "Manual das emoções",
    description:
      "Este pequeno ebook vem “bater à sua porta” para que se obrigue a olhar para dentro de si. Respire… Essa é uma jornada de autoconhecimento que, passo a passo, poderá trazer uma transformação significativa à sua vida.",
    imageUrl:
      "https://static-media.hotmart.com/y1uf86k0B2tCl3V3ASLOBk3QtGM=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/0085877a-17d3-4e92-9bb2-d2f408c169f2/IMG_6941.jpeg?w=920",
    link: "https://hotmart.com/pt-br/marketplace/produtos/hagsxd-manual-das-emocoes-cyj37/O94636283Y",
  },
  {
    title: "Diga adeus à ansiedade e ejaculação precoce",
    description:
      "Neste livro contem 4 capítulos, onde você aprenderá: As causas da ejaculação precoce e como identificá-las. Técnicas eficazes para prolongar a relação sexual. Exercícios e práticas para fortalecer o controle sobre a ejaculação. Como lidar com questões emocionais relacionadas à ejaculação precoce. Dicas para melhorar a comunicação com seu parceiro(a) sobre o assunto. Com abordagens práticas e  conselhos especializados.",
    imageUrl:
      "https://static-media.hotmart.com/rALizx8qByHp0SLL8YTaEvbAc-o=/300x300/smart/filters:format(webp):background_color(white)/hotmart/product_pictures/3d0217d7-8574-4153-b9cf-1ce9a1852818/600x6002.jpg?w=920",
    link: "https://hotmart.com/pt-br/marketplace/produtos/diga-adeus-a-ansiedade-e-ejaculacao-precoce/C94443497X",
  },
];

// Posts fixos do Instagram
const fixedInstagramPosts = [
  {
    title: "Silêncio - 3 situações em que ele é fundamental",
    imageUrl: "/img/feed/post1.png",
    link: "https://www.instagram.com/psipabla/reel/C-51E5sxCaW/",
  },
  {
    title: "Pacientes fora do Brasil",
    imageUrl: "/img/feed/post2.png",
    link: "https://www.instagram.com/psipabla/p/CxtDxX0rFx9/",
  },
  {
    title: "Minha história",
    imageUrl: "/img/feed/post3.png",
    link: "https://www.instagram.com/psipabla/p/C-G2wKjtHVZ/?img_index=1",
  },
];

export default function Destaques() {
  const [dynamicInstagramPosts, setDynamicInstagramPosts] = useState<
    Array<{ title: string; imageUrl: string; link: string }>
  >([]);

  const [showMore, setShowMore] = useState<boolean[]>(Array(2).fill(false));

  useEffect(() => {
    // Simulação de posts dinâmicos do Instagram
    setDynamicInstagramPosts([
      {
        title: "Post Dinâmico 1",
        imageUrl: "URL_DA_IMAGEM_DO_POST_DINAMICO_1",
        link: "https://www.instagram.com/postdinamico1",
      },
      {
        title: "Post Dinâmico 2",
        imageUrl: "URL_DA_IMAGEM_DO_POST_DINAMICO_2",
        link: "https://www.instagram.com/postdinamico2",
      },
      {
        title: "Post Dinâmico 3",
        imageUrl: "URL_DA_IMAGEM_DO_POST_DINAMICO_3",
        link: "https://www.instagram.com/postdinamico3",
      },
    ]);
  }, []);

  const handleShowMore = (index: number) => {
    setShowMore((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const truncateText = (text: string, index: number, limit: number) => {
    if (text.length <= limit || showMore[index]) {
      return text;
    }
    return `${text.substring(0, limit)}...`;
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        {/* Linha 1: Produtos do Hotmart */}
        <Grid container item justifyContent="center" spacing={4}>
          {hotmartProducts.map((product, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Card
                sx={{
                  height: "100%",
                  transition: "transform 0.3s ease-in-out",
                  display: "flex",
                  flexDirection: "column",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={product.imageUrl}
                  alt={product.title}
                  sx={{ objectFit: "cover" }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                    {product.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {truncateText(product.description, index, 100)}
                  </Typography>
                  {product.description.length > 100 && (
                    <Button
                      onClick={() => handleShowMore(index)}
                      size="small"
                      sx={{ mt: 1 }}
                    >
                      {showMore[index] ? "Ver menos" : "Ver mais"}
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Linha 2: Posts Fixos do Instagram */}
        <Grid container item justifyContent="center" spacing={4}>
          {fixedInstagramPosts.map((post, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  height: "100%",
                  transition: "transform 0.3s ease-in-out",
                  display: "flex",
                  flexDirection: "column",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={post.imageUrl}
                  alt={post.title}
                  sx={{ objectFit: "cover" }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" component="div">
                    {post.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Linha 3: Posts Dinâmicos do Instagram */}
        <Grid container item justifyContent="center" spacing={4}>
          {dynamicInstagramPosts.map((post, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  height: "100%",
                  transition: "transform 0.3s ease-in-out",
                  display: "flex",
                  flexDirection: "column",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={post.imageUrl}
                  alt={post.title}
                  sx={{ objectFit: "cover" }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" component="div">
                    {post.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}
