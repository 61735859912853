import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import TiposTratamento from "../pages/TiposTratamento";
import SobreMim from "../pages/SobreMim";
import Contato from "../pages/Contato";
import Depoimentos from "../pages/Depoimentos";
import Psicoterapia from "../pages/Psicoterapia";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box } from "@mui/material";
import WhatsAppFloatingButton from "../components/WhatsAppFloatingButton";
import Destaques from "../pages/Destaques";

export default function AppRouter() {
  return (
    <Router>
      <Box
        maxWidth="100vw"
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="stretch"
        sx={{
          backgroundImage: 'url("/img/logo.png")',
          backgroundRepeat: "repeat",
          backgroundSize: "400px 400px",
          backgroundColor: "rgba(253, 236, 219, 0.35)",
          backgroundBlendMode: "overlay",
        }}
      >
        <Header />
        <Box
          component="main"
          sx={{
            my: 4,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/psicoterapia" element={<Psicoterapia />} />
            <Route path="/tratamentos" element={<TiposTratamento />} />
            <Route path="/depoimentos" element={<Depoimentos />} />
            <Route path="/sobre" element={<SobreMim />} />
            <Route path="/contato" element={<Contato />} />
            <Route path="/destaques" element={<Destaques />} />
          </Routes>
        </Box>
        <WhatsAppFloatingButton />
        <Footer />
      </Box>
    </Router>
  );
}
