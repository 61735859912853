import {
  Box,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import imgPabla from "../../img/profile2.jpeg";

export default function Home() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      {/* Desktop and Tablet Layout */}
      {(isDesktop || isTablet) && (
        <Box
          id="home-container"
          sx={{
            maxWidth: "1200px", // Limitar a largura máxima
            margin: "0 auto", // Centralizar o conteúdo
            padding: { sm: 4, md: 6 }, // Adicionar padding
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Box
                  component="img"
                  src={imgPabla}
                  alt="Foto da Psicóloga Pabla Amaral"
                  sx={{
                    borderRadius: "50%",
                    width: { xs: "15rem", md: "20rem" },
                    height: { xs: "15rem", md: "20rem" },
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h1" component="h1" gutterBottom>
                Psicoterapia Online
              </Typography>
              <Typography variant="body1" gutterBottom>
                Nada de espera ou chegar atrasado na sua consulta, agora suas
                <strong> sessões de terapia</strong> podem ser feitas no
                conforto da sua casa ou escritório.
                <br />A consulta virtual por vídeo e chat é 100% protegida e
                sigilosa, além de atender todas as regulamentações dos Conselhos
                Federais de Psicologia e Medicina do Brasil.
              </Typography>
              <Typography variant="h2" component="h2" gutterBottom mt={4}>
                Praticidade e fácil acesso
              </Typography>
              <Typography variant="body1" gutterBottom>
                O <strong>atendimento online</strong> é amplamente eficiente e
                muitos estudos atestam isso. A sessão de terapia on-line
                proporciona resultados incríveis. Agende uma consulta
                psicológica em apenas alguns cliques. Atendimento para adultos,
                crianças e adolescentes.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            padding={{ sm: 0, md: 4 }}
            pt={{ sm: 4, md: 4 }}
            maxWidth="100%"
          >
            <Grid item xs={12}>
              <Typography variant="h2" component="h2" gutterBottom>
                Brasileiros no Exterior
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Brasileiros que moram fora do nosso país</strong>,
                procuram por terapia online porque consideram importante cuidar
                de sua saúde mental. Falar sobre saudade da família,
                dificuldades com a língua, estilo de vida, cultura, rotina,
                emprego, clima, relações e diversos outros fatores podem
                dificultar a adaptação no novo país, neste momento a terapia
                ajuda a ver as situações por outros ângulos. Já passei por essa
                experiência na minha vida, e sei exatamente os desafios
                enfrentados, e por esse motivo, passei a atender pacientes fora
                do país. Ofereço
                <strong> suporte personalizado </strong>com ferramentas para
                ajudar você a construir uma vida plena e significativa.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            padding={0}
            maxWidth="100%"
          >
            {[
              "us",
              "ie",
              "br",
              "gb-eng",
              "it",
              "de",
              "au",
              "pt",
              "ru",
              "ae",
              "es",
              "fr",
              "ca",
            ].map((flag) => (
              <Grid item key={flag}>
                <Box
                  component="img"
                  src={`https://flagcdn.com/120x90/${flag}.png`}
                  srcSet={`https://flagcdn.com/240x180/${flag}.png 2x`}
                  width="40"
                  height="30"
                  alt={`Bandeira do país ${flag.toUpperCase()}`} // Acessibilidade melhorada
                  sx={{
                    maxWidth: "30px",
                    height: "auto",
                    display: "block",
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* Mobile Layout */}
      {!isDesktop && !isTablet && (
        <Container sx={{ px: 4 }}>
          <Box display="flex" justifyContent="center">
            <Box
              component="img"
              src={imgPabla}
              alt="Psicóloga Pabla Amaral"
              sx={{
                borderRadius: "50%",
                width: "15rem",
                height: "15rem",
                objectFit: "cover",
              }}
            />
          </Box>
          <Typography variant="h3" component="h1" gutterBottom mt={4}>
            Psicoterapia Online
          </Typography>
          <Typography variant="body1" gutterBottom>
            Nada de espera ou chegar atrasado na sua consulta, agora suas
            <strong> sessões de terapia</strong> podem ser feitas no conforto da
            sua casa ou escritório.
            <br />A consulta virtual por vídeo e chat é 100% protegida e
            sigilosa, além de atender todas as regulamentações dos Conselhos
            Federais de Psicologia e Medicina do Brasil.
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom mt={4}>
            Praticidade e fácil acesso
          </Typography>
          <Typography variant="body1" gutterBottom>
            O <strong>atendimento online</strong> é amplamente eficiente e
            muitos estudos atestam isso. A sessão de terapia on-line proporciona
            resultados incríveis. Agende uma consulta psicológica em apenas
            alguns cliques. Atendimento para adultos, crianças e adolescentes.
          </Typography>
          <Typography variant="h4" component="h2" gutterBottom mt={4}>
            Brasileiros no Exterior
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Brasileiros</strong> que moram fora do nosso país, procuram
            por terapia online porque consideram importante cuidar de sua saúde
            mental. Falar sobre saudade da família, dificuldades com a língua,
            estilo de vida, cultura, rotina, emprego, clima, relações e diversos
            outros fatores podem dificultar a adaptação no novo país, neste
            momento a terapia ajuda a ver as situações por outros ângulos. Já
            passei por essa experiência na minha vida, e sei exatamente os
            desafios enfrentados, e por esse motivo, passei a atender pacientes
            fora do país. Ofereço
            <strong> suporte personalizado </strong>com ferramentas para ajudar
            você a construir uma vida plena e significativa.
          </Typography>
          <Grid container spacing={1} justifyContent="center" pt={2}>
            {[
              "us",
              "ie",
              "br",
              "gb-eng",
              "it",
              "de",
              "au",
              "pt",
              "ru",
              "ae",
              "es",
              "fr",
              "ca",
            ].map((flag) => (
              <Grid item key={flag}>
                <Box
                  component="img"
                  src={`https://flagcdn.com/120x90/${flag}.png`}
                  srcSet={`https://flagcdn.com/240x180/${flag}.png 2x`}
                  width="40"
                  height="30"
                  alt={`Bandeira do país ${flag.toUpperCase()}`} // Acessibilidade melhorada
                  sx={{
                    maxWidth: "30px",
                    height: "auto",
                    display: "block",
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </Box>
  );
}
