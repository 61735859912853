import {
  Container,
  Typography,
  Box,
  ListItem,
  Link as MuiLink,
  Grow,
  Grid,
  List,
} from "@mui/material";
import {
  FaLinkedin,
  FaInstagram,
  FaWhatsapp,
  FaEnvelope,
} from "react-icons/fa";

export default function Contato() {
  const items = [
    {
      href: "https://www.linkedin.com/in/psi-pabla/",
      icon: <FaLinkedin size={40} color="#0077B5" />,
      label: "Linkedin",
      delay: 500,
    },
    {
      href: "https://www.instagram.com/psipabla/",
      icon: <FaInstagram size={40} color="#E1306C" />,
      label: "Instagram",
      delay: 1500,
    },
    {
      href: "https://api.whatsapp.com/send?phone=555196950402",
      icon: <FaWhatsapp size={40} color="#25D366" />,
      label: "Whatsapp",
      delay: 3000,
    },
    {
      href: "mailto:psicologapabla@gmail.com",
      icon: <FaEnvelope size={40} color="#D44638" />,
      label: "Email",
      delay: 4000,
    },
  ];

  return (
    <Container
      maxWidth="sm"
      sx={{ textAlign: "center", mt: 4, maxWidth: "80vw", margin: "0 auto" }}
    >
      <Box>
        <Typography variant="h4" component="h1" gutterBottom>
          Contato
        </Typography>
        {/* Usar <List> para garantir a semântica correta */}
        <List>
          <Grid container spacing={2} justifyContent="center">
            {items.map((item, index) => (
              <Grow
                in
                key={index}
                style={{ transformOrigin: "0 0 0" }}
                {...(index ? { timeout: item.delay } : {})}
              >
                <Grid item xs={6} md={3}>
                  <ListItem
                    sx={{
                      flexDirection: "column",
                      alignItems: "center",
                      m: 2,
                      padding: 0,
                    }}
                  >
                    <MuiLink
                      href={item.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      {item.icon}
                      <Typography variant="body1" sx={{ mt: 1 }}>
                        {item.label}
                      </Typography>
                    </MuiLink>
                  </ListItem>
                </Grid>
              </Grow>
            ))}
          </Grid>
        </List>
      </Box>
    </Container>
  );
}
